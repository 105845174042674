const tableData = [
  {
    id: 1,
    title: 'Recruiting Mantotal_studentsr',
    total_students: 29,
    status: true
  }, {
    id: 2,
    title: 'Quality Engineer',
    total_students: 62,
    status: "A"
  }, {
    id: 3,
    title: 'Senior Editor',
    total_students: 26,
    status: false
  }, {
    id: 4,
    title: 'Programmer II',
    total_students: 26,
    status: "I"
  },
  {
    id: 5,
    title: 'Data Coordiator',
    total_students: 31,
    status: "I"
  }, {
    id: 6,
    title: 'Data Coordiator',
    total_students: 59,
    status: "A"
  }, {
    id: 7,
    title: 'Senior Developer',
    total_students: 52,
    status: "I"
  },
]

export { tableData };
